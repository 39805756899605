<template>
  <section class="">
    <b-tabs>
        <b-tab-item label="Adapters" icon="power-plug">
          <aside class="menu is-kx-blue">
            <ul class="menu-list">
              <li>
                <router-link
                  to="configuration/inboundadapters"
                  >Configure inbound adapters
                </router-link>
              </li>
              <li>
                <router-link to="configuration/outboundadapters">
                  Configure outbound adapters
                </router-link>
              </li>
            </ul>
          </aside>
        </b-tab-item>
        <b-tab-item
          label="Merchants"
          class="is-kx-blue"
          icon="bank">
          <ul class="menu-list">
            <li>
              <router-link
                to="configuration/merchants">Configure merchants
              </router-link>
            </li>
            <li>
              <router-link
                to="configuration/merchantmappings">Configure merchant mappings
              </router-link>
            </li>
          </ul>
        </b-tab-item>
    </b-tabs>
    <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: 'Configuration',
};
</script>
